import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";

import {loginLink} from "../../config";

export default function FooterNav() {
  return (
    <Box
      color="custom"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
    >
      <ButtonBase>
        <Typography component={Link} to="/" color="blue5" fontSize={18}>
          Home
        </Typography>
      </ButtonBase>
      <ButtonBase component={Link} to="/about" sx={{ mt: "12px" }}>
        <Typography color="blue5" fontSize={18}>
          About
        </Typography>
      </ButtonBase>
      <ButtonBase component={Link} to="/contact" sx={{ mt: "12px" }}>
        <Typography color="blue5" fontSize={18}>
          Contact
        </Typography>
      </ButtonBase>
      <ButtonBase target="_blank" component="a" href={loginLink} sx={{ mt: "12px" }}>
        <Typography color="blue5" fontSize={18}>
          Login
        </Typography>
      </ButtonBase>
      <ButtonBase component={Link} to="/contact" sx={{ mt: "12px" }}>
        <Typography color="blue5" fontSize={18}>
          Get Help
        </Typography>
      </ButtonBase>
    </Box>
  );
}
