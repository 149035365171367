import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import ButtonBase from "@mui/material/ButtonBase";
import Hidden from "@mui/material/Hidden";

import useGetLiveDemo from "../../hooks/useGetLiveDemo";
import { loginLink } from "../../config";
import Solutions from "./Solutions";

export default function Header() {
  const getLiveDemo = useGetLiveDemo();

  return (
    <Container>
      <Hidden mdUp>
        <Box pt={3} display="flex" justifyContent="space-between">
          <ButtonBase component={Link} to="/">
            <img width="76px" src="/images/glimpse_logo.png" alt="logo" />
          </ButtonBase>
          <Box display="flex" alignItems="center">
            <Button onClick={getLiveDemo} variant="contained" color="primary">
              Get a live demo
            </Button>
          </Box>
        </Box>
        <Box mt="28px" display="flex" justifyContent="space-between">
          <Button
            component={Link}
            to="/"
            color="secondary"
            sx={{ fontSize: 14 }}
          >
            Home
          </Button>
          <Solutions sx={{ fontSize: 14 }} />
          <Button
            component={Link}
            to="/about"
            color="secondary"
            sx={{ fontSize: 14 }}
          >
            About
          </Button>
          <Button
            component={Link}
            to="/contact"
            color="secondary"
            sx={{ fontSize: 14 }}
          >
            Contact
          </Button>
          <ButtonBase
            component="a"
            href={loginLink}
            target="_blank"
            sx={{ fontSize: 14, color: "#00698C" }}
          >
            Log In
          </ButtonBase>
        </Box>
      </Hidden>
      <Hidden mdDown>
        <Box
          display="flex"
          justifyContent="space-between"
          height="116px"
          py="40px"
          className="header"
        >
          <ButtonBase component={Link} to="/">
            <img width="130px" src="/images/glimpse_logo.png" alt="logo" />
          </ButtonBase>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <ButtonBase
              component="a"
              href={loginLink}
              target="_blank"
              sx={{ fontSize: 14, color: "#00698C" }}
            >
              Log In
            </ButtonBase>
            <Box display="flex">
              <Button component={Link} to="/" color="secondary" sx={{ mr: 3 }}>
                Home
              </Button>
              <Solutions sx={{ mr: 3 }} />
              <Button
                component={Link}
                to="/about"
                color="secondary"
                sx={{ mr: 3 }}
              >
                About
              </Button>
              <Button
                component={Link}
                to="/contact"
                color="secondary"
                sx={{ mr: 3 }}
              >
                Contact
              </Button>
              <Button onClick={getLiveDemo} variant="contained" color="primary">
                Get a live demo
              </Button>
            </Box>
          </Box>
        </Box>
      </Hidden>
    </Container>
  );
}
