import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useHubSpotTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // Check if HubSpot tracking script is available
    if (window._hsq) {
      window._hsq.push(['setPath', location.pathname]);
      window._hsq.push(['trackPageView']);
    }
  }, [location.pathname]);
};

export default useHubSpotTracking;
