import { Helmet } from 'react-helmet-async';
import Box from "@mui/material/Box";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Player from "./Player";
import AssetManagement from "./AssetManagement";
import OurPartners from "./OurPartners";
import OurMission from "./OurMission";
import SignUp from "./SignUp";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Glimpse</title>
        <meta name="description" content="Glimpse" />
      </Helmet>
      <Box
        sx={{
          backgroundColor: "#E3F4F8",
          backgroundImage: "url(/images/home-background.png)",
          backgroundSize: "100% 100%",
          backgroundPosition: "center",
        }}
      >
        <Header />
        <Player />
      </Box>
      <AssetManagement />
      <OurPartners />
      <SignUp />
      <OurMission />
      <Footer />
    </>
  );
}
