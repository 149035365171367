import { useNavigate } from "react-router-dom";

export default function useGetLiveDemo() {
  const navigate = useNavigate();
  return () => {
    navigate("/");
    setTimeout(() => {
      const element = document.querySelector("#signUp");
      const topPos = element.getBoundingClientRect().top + window.pageYOffset;

      window.scrollTo({
        top: topPos,
        behavior: "smooth",
      });
    });
  };
}
