import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export default function OperationItem({title, children}) {
  return (
    <Box display="flex" mt="30px" maxWidth={629}>
      <Typography color="brown5">
        <CheckCircleOutlineIcon sx={{ fontSize: 59 }} />
      </Typography>
      <Box ml="34px">
        <Typography mt={1.5} variant="h3" color="brown5">
          {title}
        </Typography>
        <Typography mt={1} color="brown5">
          {children}
        </Typography>
      </Box>
    </Box>
  );
}
