import React from "react";
import { Helmet } from "react-helmet-async";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function WarehouseManagement() {
  return (
    <>
      <Helmet>
        <title>Warehouse Management</title>
        <meta name="description" content="Warehouse Management" />
      </Helmet>
      <Header />
      <Box bgcolor="#FAFBFB" pb={15}>
        <Container>
          <Typography variant="h1" sx={{ pt: "82px" }}>
            Warehouse Management
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <Typography variant="body1" sx={{ mt: 3, whiteSpace: "pre-line" }}>
                {`Automate your warehouse with enhanced efficiency through the Glimpse tools.  Optimize and automate tasks, tracking, storage vacancy, labor, and billing on one platform.  With Glimpse, your operations will be able to streamline processes and maximize space and productivity, while reducing costs and errors and enhancing accuracy and customer service.`}
                <Typography mt={5}>
                  <CheckCircleIcon
                    sx={{ fontSize: 16, color: "#00698C", mb: "-2px" }}
                  />{" "}
                  Cross-docking and transfer monitoring and management
                </Typography>
                <Typography mt={0.5}>
                  <CheckCircleIcon
                    sx={{ fontSize: 16, color: "#00698C", mb: "-2px" }}
                  />{" "}
                  Live occupancy metrics for optimal storage efficiency
                </Typography>
                <Typography mt={0.5}>
                  <CheckCircleIcon
                    sx={{ fontSize: 16, color: "#00698C", mb: "-2px" }}
                  />{" "}
                  Tracking of bill of ladings and packing slips
                </Typography>
                <Typography mt={0.5}>
                  <CheckCircleIcon
                    sx={{ fontSize: 16, color: "#00698C", mb: "-2px" }}
                  />{" "}
                  Analytics and reporting features
                </Typography>
              </Typography>
            </Grid>
            <Hidden>
              <Grid item md={7}>
                <Box p={2}>
                  <Box
                    position="relative"
                    component="img"
                    maxWidth="calc(100% - 6px)"
                    src="/images/solutions-warehouse-management.png"
                    alt=""
                  />
                </Box>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </>
  );
}
