import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import OperationItem from "./OperationItem";
import SignUpForm from "./SignUpForm";

export default function SignUp() {
  return (
    <Box id="signUp" bgcolor="#00698C" pb={{ xs: 0, md: "44px" }}>
      <Container>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={6}>
            <Typography variant="h3" fontSize={28} color="brown5">
              Just Glimpse It
            </Typography>
            <Typography mt="4px" color="brown5">
              Are you ready to revolutionize your asset and warehouse management processes?
              Don’t let outdated methods hold you back – just Glimpse It and unlock the full
              potential of your operation.
            </Typography>
            <Box pt="10px">
              <OperationItem title="Premium Customer Service">
                Need Something? Just Ask! Our team provides comprehensive onboarding
                and on-going support for all users.
              </OperationItem>
              <OperationItem title="Asset Management">
                Are you drowning in spreadsheets? Using software that doesn’t mesh with how you operate?
                Maybe you don’t have a process in place?
                The Glimpse team are the experts- let us create a process for you!
              </OperationItem>
              <OperationItem title="Client Roundtables">
                We are committed to transparency and remaining the most customizable asset management
                system on the market. Our clients feedback, observations, and feature requests is what
                drives one of our company’s core philosophy: Continual Adaptation and Innovation.
              </OperationItem>
            </Box>
          </Grid>
          <Grid item xs={0} sm={2} md={0} lg={0} xl={0} sx={{ display: { xs: 'none', md: 'none', lg: 'none', xl: 'none', sm: 'block' } }}></Grid>
          <Grid item xs={12} sm={8} md={5} sx={{ position: "relative" }}>
            <Box
              p="60px"
              px={{ xs: 2, md: "60px" }}
              maxWidth={521}
              bgcolor="white"
              mx={{ xs: "-16px", md: 0 }}
              boxShadow="4px 6px 24px rgba(0, 0, 0, 0.12)"
              borderRadius={{ xs: 0, md: 7 }}
            >
              <Typography variant="h2">Sign up for a live demo</Typography>
              <SignUpForm />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
