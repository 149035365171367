import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function PrivacyTerms() {
  return (
    <Box display="flex">
      <Typography
        component="a"
        target="_blank"
        href="/Privacy Policy 3.9.pdf"
        color="blue5"
        fontSize={18}
        mr={1}
        sx={{ textDecoration: "none" }}
      >
        Privacy
      </Typography>
      <Typography color="blue5" fontSize={18}>
        Terms
      </Typography>
    </Box>
  );
}
