import { Helmet } from "react-helmet-async";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ContactForm from "./ContactForm";

export default function Contact() {
  return (
    <>
      <Helmet>
        <title>Contact</title>
        <meta name="description" content="Contact" />
      </Helmet>
      <Header />
      <Box
        mt="82px"
        mb="100px"
        sx={{
          background:
            "linear-gradient(to bottom, #DEF2F5 0px, #DEF2F5 419px, white 419px, white 100%)",
        }}
      >
        <Container>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Typography variant="h2">Let's connect</Typography>
              <Typography mt={3} variant="body1">
                Reach out to us to learn more about how Glimpse can streamline your processes 
                by reducing redundant efforts and automating manual tasks.
              
              </Typography>
              <Box mt={3}>
                <img
                  style={{ maxWidth: "100%" }}
                  src="/images/warehouse-staff.png"
                  alt="map"
                />
              </Box>
              <Typography
                mt="47px"
                textTransform="uppercase"
                fontSize={20}
                color="#00698C"
                sx={{ whiteSpace: "pre-line" }}
              >
                {`Your Trusted Partner for New Possibilities`}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                p="60px"
                bgcolor="#FAFBFB"
                boxShadow="4px 6px 24px rgba(0, 0, 0, 0.12)"
                mx={{ xs: "-16px", md: 0 }}
                px={{ xs: 2, md: "60px" }}
                borderRadius={{ xs: 0, md: 7 }}
              >
                <Typography variant="h2">Book your Glimpse demo!</Typography>
                <Typography mt={3} variant="body1">
                Fill out the form below and we'll promptly 
                reach out to discuss how Glimpse can improve your operations.
              </Typography>
                
                <ContactForm />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </>
  );
}
