import React, { useEffect } from 'react';

const HubSpotTracker = () => {
  useEffect(() => {
    // Check if the HubSpot script is already loaded
    if (!document.getElementById('hs-script-loader')) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'hs-script-loader';
      script.async = true;
      script.defer = true;
      script.src = 'https://js.hs-scripts.com/48023499.js';
      document.body.appendChild(script);
    }
  }, []);

  return null; // This component doesn't render anything visually
};

export default HubSpotTracker;
