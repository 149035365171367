import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Fade from "@mui/material/Fade";

const partners = [
  { width: 220, src: "/images/partners/onerelo-logo.png" },
  { width: 160, src: "/images/partners/tnt.png" },
  { width: 243, src: "/images/partners/greener-source.png" },
  { width: 210, src: "/images/partners/visions.png" },
  { width: 130, src: "/images/partners/holitna.png" },
];

export default function OurPartners() {
  const [index, setIndex] = useState(0);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prev) => (prev + 1 === partners.length ? 0 : prev + 1));
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  });

  return (
    <Box bgcolor="#E7E7E7">
      <Container>
        <Typography pt={{ xs: "30px", md: "54px" }} variant="h2" align="center">
          Partners
        </Typography>
        <Box
          mt="38px"
          pb="85px"
          display="flex"
          alignItems="center"
          justifyContent={{ xs: "center", md: "space-between" }}
        >
          {isDesktop ? (
            <>
              <img width={220} src="/images/partners/onerelo-logo.png" alt="" />
              <img width={160} src="/images/partners/tnt.png" alt="" />
              <img
                width={243}
                src="/images/partners/greener-source.png"
                alt=""
              />
              <img width={210} src="/images/partners/visions.png" alt="" />
              <img width={130} src="/images/partners/holitna.png" alt="" />
            </>
          ) : (
            <Box height="76px">
              <Fade in key={partners[index].src}>
                <img
                  width={partners[index].width}
                  src={partners[index].src}
                  alt=""
                />
              </Fade>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
}
