import { createTheme } from "@mui/material/styles";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";

const theme = createTheme({
  palette: {
    primary: {
      main: "#EFBE25",
    },
    secondary: {
      main: "#0A1F41",
    },
    custom: {
      light: "#E3F4F8",
      main: "#E3F4F8",
      dark: "#E3F4F8",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    darkBlue: {
      light: "#4E6388",
      main: "#4E6388",
      dark: "#4E6388",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    teal1: "#00698C",
    blue5: "#E3F4F8",
    brown5: "#FAFBFB",
  },
  typography: {
    fontFamily: '"Open Sans"',
  },
  components: {
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: <CheckBoxOutlinedIcon />,
      },
      styleOverrides: {
        root: {
          color: "#00698C",
          "&.Mui-checked": {
            color: "#00698C",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#00698C",
          textDecorationColor: "#00698C",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        input: {
          paddingLeft: 20,
        },
        root: {
          paddingLeft: 20,
          borderRadius: 50,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: 18,
          fontWeight: 600,
        },
        contained: {
          borderRadius: 25,
          color: "#0A1F41",
        },
      },
    },
  },
});

theme.typography.h1 = {
  fontSize: "40px",
  fontWeight: 600,
  [theme.breakpoints.down("md")]: {
    fontSize: "30px",
  },
};

theme.typography.h2 = {
  fontSize: "32px",
  fontWeight: 600,
  [theme.breakpoints.down("md")]: {
    fontSize: "26px",
  },
};

theme.typography.h3 = {
  fontSize: "26px",
  fontWeight: 700,
  [theme.breakpoints.down("md")]: {
    fontSize: "22px",
  },
};

export default theme;
