import React, { useState, useContext } from "react";
import Snackbar from "@mui/material/Snackbar";
import { SnackbarProvider } from "notistack";
import { Alert, AlertTitle } from "@mui/material";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";

const NotificationContext = React.createContext(null);

const titles = { success: "Success", error: "Error" };
const InitOptions = { open: false, message: "", status: "success" };

export const NotificationProvider = ({ children }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [options, setOptions] = useState(InitOptions);

  const handleClose = () => {
    setOptions((o) => ({ ...InitOptions, status: o.status }));
  };

  const notifySuccess = (message) => {
    setOptions({ open: true, message, status: "success" });
  };

  const notifyError = (error) => {
    setOptions({ open: true, message: error, status: "error" });
  };

  return (
    <>
      <NotificationContext.Provider value={{ notifySuccess, notifyError }}>
        <SnackbarProvider>{children}</SnackbarProvider>
      </NotificationContext.Provider>
      <Snackbar
        anchorOrigin={{
          vertical: isMobile ? "bottom" : "top",
          horizontal: "right",
        }}
        open={options.open}
        autoHideDuration={10000}
        onClose={handleClose}
      >
        <Box minWidth={300} clone>
          <Alert
            variant="filled"
            onClose={handleClose}
            severity={options.status}
          >
            <AlertTitle>{titles[options.status]}</AlertTitle>
            {options.message}
          </Alert>
        </Box>
      </Snackbar>
    </>
  );
};

export const useNotification = () => useContext(NotificationContext);

export default NotificationContext;
