import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function Input({ name, placeholder, control, ...props }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState, formState }) => {
        return (
          <TextField
            fullWidth
            hiddenLabel
            placeholder={placeholder}
            variant="filled"
            InputProps={{ disableUnderline: true }}
            {...field}
            {...props}
            error={!!fieldState.error}
            helperText={
              fieldState.error &&
              capitalizeFirstLetter(`${fieldState.error?.message}`)
            }
          />
        );
      }}
    />
  );
}
