import { Helmet } from "react-helmet-async";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";

import Header from "./Header/Header";
import Footer from "./Footer/Footer";

export default function Login() {
  return (
    <>
      <Helmet>
        <title>Login</title>
        <meta name="description" content="Login" />
      </Helmet>
      <Header></Header>
      <Box bgcolor="blue5">
        <Container>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            pt="89px"
            pb="67px"
            sx={{
              backgroundImage:
                "url(/images/cloud-small.png), url(/images/cloud-medium.png), url(/images/cloud-big.png)",
              backgroundPosition: "210px 57px, right 288px, left 251px",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Box
              p="60px"
              px={{ xs: 2, md: "60px" }}
              maxWidth={630}
              bgcolor="white"
              mx={{ xs: "-16px", md: 0 }}
              boxShadow="4px 6px 24px rgba(0, 0, 0, 0.12)"
              borderRadius={{ xs: 0, md: 7 }}
            >
              <Typography variant="h2">
                Existing customers login here
              </Typography>
              <Box mt={5}>
                <TextField
                  fullWidth
                  hiddenLabel
                  placeholder="Email address*"
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                />
              </Box>
              <Box mt="20px">
                <TextField
                  fullWidth
                  hiddenLabel
                  placeholder="Password*"
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                />
              </Box>
              <Box mt="12px">
                <FormControlLabel
                  label="Remember me for 30 days"
                  control={
                    <Checkbox
                      checkedIcon={<CheckBoxOutlinedIcon />}
                      sx={{
                        color: "#00698C",
                        "&.Mui-checked": {
                          color: "#00698C",
                        },
                      }}
                      size="large"
                    />
                  }
                />
              </Box>
              <Box mt="12px">
                <Link>Forgot password?</Link>
              </Box>
              <Box mt={5}>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  Login
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
}
