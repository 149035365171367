import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

export default function OurMissionInfo() {
  return (
    <>
      <Typography variant="h2">Our mission</Typography>
      <Typography>
        Our mission is to empower our customers with innovative asset and warehouse
        management software solutions that drive efficiency, accuracy, and profitability.
        We are committed to delivering cutting-edge technology that streamlines operations,
        enhances visibility,  and enables informed decision making.
        With our dedicated support and ongoing commitment to innovation, we strive to be the trusted partner that helps our customers succeed.
      </Typography>
      <Button
        component={Link}
        to="/about"
        variant="contained"
        color="darkBlue"
        sx={{ mt: "35px", color: "white" }}
      >
        Learn more
      </Button>
    </>
  );
}
