import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function ManagementItem({ title, items, children, fullWidth }) {
  return (
    <>
      <Typography pt={{ xs: "60px", md: "111px" }} variant="h3">
        {title}
      </Typography>
      <Typography pt="4px" maxWidth={fullWidth ? 650 : 530} sx={{ whiteSpace: "pre-line" }}>
        {children}
      </Typography>
      <Box mt={3}>
        {items.map((item) => (
          <Typography key={item} mt={0.5} maxWidth={530}>
            <CheckCircleIcon
              sx={{ fontSize: 16, color: "#00698C", mb: "-2px" }}
            />{" "}
            {item}
          </Typography>
        ))}
      </Box>
    </>
  );
}
