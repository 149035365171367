import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Hidden from "@mui/material/Hidden";

import useGetLiveDemo from "../../hooks/useGetLiveDemo";
import FooterNav from "./FooterNav";
import FooterInfo from "./FooterInfo";
import PrivacyTerms from "./PrivacyTerms";

export default function Footer() {
  const getLiveDemo = useGetLiveDemo();
  const year = new Date().getFullYear();

  return (
    <>
      <Hidden smUp>
        <Box pt="30px" pb="60px" bgcolor="#00698C">
          <Container>
            <Box display="flex" justifyContent="space-between">
              <FooterNav />
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Button
                  onClick={getLiveDemo}
                  variant="contained"
                  color="primary"
                  sx={{ mb: "10px" }}
                >
                  Get a live demo
                </Button>
                <FooterInfo />
              </Box>
            </Box>
            <Box py={1}>
              <PrivacyTerms />
            </Box>
            <Box display="flex" alignItems="flex-end">
              <Typography mt="2px" color="blue5">
                © 2024, Glimpse, all rights reserved.
              </Typography>
            </Box>
          </Container>
        </Box>
      </Hidden>
      <Hidden smDown>
        <Box py="80px" bgcolor="#00698C">
          <Container>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex">
                <FooterNav />
                <Box ml={4}>
                  <Button
                    onClick={getLiveDemo}
                    variant="contained"
                    color="primary"
                  >
                    Get a live demo
                  </Button>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                alignItems="center"
              >
                <PrivacyTerms />
                <Typography ml="21px" color="blue5">
                  © {year}, Glimpse, all rights reserved.
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <FooterInfo />
              </Box>
            </Box>
          </Container>
        </Box>
      </Hidden>
    </>
  );
}
