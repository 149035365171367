import { useEffect, useRef } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";
import Box from "@mui/material/Box";

import useGetLiveDemo from "../../hooks/useGetLiveDemo";

const speed = 200;
const texts = [
  "furniture.",
  "boxes.",
  "technology.",
  "equipment.",
  "laboratories.",
];

export default function Player() {
  const getLiveDemo = useGetLiveDemo();
  const titleRef = useRef();

  useEffect(() => {
    let i = 0;
    if (titleRef.current?.innerHTML?.length === 13) {
      typeWriter(0);
    }
    function typeWriter(index) {
      const text = texts[index];
      if (i < text.length) {
        titleRef.current.innerHTML += text.charAt(i);
        i++;
        setTimeout(() => typeWriter(index), speed);
      } else {
        i = 0;
        titleRef.current.innerHTML = "Glimpse your ";
        texts[index + 1] ? typeWriter(index + 1) : typeWriter(0);
      }
    }
  }, []);

  return (
    <Container>
      <Grid container spacing={5}>
        <Grid item sm={12} md={6}>
          <Typography variant="h2" ref={titleRef}>
            Manage Your Assets and Warehouse.
          </Typography>
          <Typography variant="h2">Anytime, anywhere.</Typography>
          <Typography mt="10px" maxWidth={440}>
            Glimpse is a real time asset and warehouse management system that
            allows customers and service providers to capture, track, manage,
            and measure their most important assets. 
          </Typography>
          <Button
            onClick={getLiveDemo}
            sx={{ mt: "22px", display: { xs: "none", md: "block" } }}
            variant="contained"
            color="primary"
          >
            Get a live demo
          </Button>
        </Grid>
        <Grid item sm={12} md={6}>
          <Hidden>
            <Box position="relative">
              <Box p={2}>
                <Box
                  position="relative"
                  component="img"
                  maxWidth="calc(100% - 6px)"
                  src="/images/capture-page.png"
                  alt=""
                />
              </Box>
            </Box>
          </Hidden>
        </Grid>
      </Grid>
    </Container>
  );
}
