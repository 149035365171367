import * as yup from "yup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import { url } from "../../config";
import { useNotification } from "../../context/Notification";
import useYupValidationResolver from "../../hooks/useYupValidationResolver";
import Input from "../Fields/Input";

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
});

export default function ContactForm() {
  const { notifySuccess } = useNotification();
  const resolver = useYupValidationResolver(schema);
  const { control, handleSubmit, formState, reset } = useForm({
    defaultValues: {},
    resolver,
  });
  const { isSubmitting } = formState;

  const onSubmit = (values) => {
    return fetch(`${url}/contact_forms?m=glimpses`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    }).then(() => {
      reset({ name: "", email: "", company: "", role: "", how_did_you_hear_about_us: "" });
      notifySuccess("Your demo request has been received. Our team will reach out to you shortly!");
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
    <Box mt={5}>
      <Input name="name" placeholder="Name" control={control} />
    </Box>
    <Box mt="20px">
      <Input name="email" placeholder="Email" control={control} />
    </Box>
    <Box mt="20px">
      <Input name="company" placeholder="Company" control={control} />
    </Box>
    <Box mt="20px">
      <Input name="role" placeholder="Role" control={control} />
    </Box>
    <Box mt="20px">
      <Input
        name="how_did_you_hear_about_us"
        placeholder="How did you hear about us?"
        control={control}
      />
      </Box>

      <Box mt={4}>
        <input
          type="checkbox"
          name="contact_me_by_fax_only"
          value="1"
          style={{ display: "none" }}
          tabIndex="-1"
          autoComplete="off"
        />
        <Button
          type="submit"
          disabled={isSubmitting}
          fullWidth
          variant="contained"
          color="primary"
        >
          Submit
        </Button>
      </Box>
    </form>
  );
}
