import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import AboutUs from "./components/AboutUs";
import Login from "./components/Login";
import Contact from "./components/Contact/Contact";
import Home from "./components/Home/Home";
import AssetCapture from "./components/Solutions/AssetCapture";
import InventoryManagement from "./components/Solutions/InventoryManagement";
import WarehouseManagement from "./components/Solutions/WarehouseManagement";
import BillingAndReceiving from "./components/Solutions/BillingAndReceiving";
import TrackIt from "./components/Solutions/TrackIt";
import { NotificationProvider } from "./context/Notification";
import useHubSpotTracking from "./hooks/useHubSpotTracking";

function HubSpotTracking() {
  useHubSpotTracking();
  return null;
}

export default function App() {
  return (
    <HelmetProvider>
      <NotificationProvider>
        <BrowserRouter>
          <HubSpotTracking />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/login" element={<Login />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/asset-capture" element={<AssetCapture />} />
            <Route
              path="/inventory-management"
              element={<InventoryManagement />}
            />
            <Route
              path="/warehouse-management"
              element={<WarehouseManagement />}
            />
            <Route path="/track-it" element={<TrackIt />} />
            <Route path="/billing-and-receiving" element={<BillingAndReceiving />} />
          </Routes>
        </BrowserRouter>
      </NotificationProvider>
    </HelmetProvider>
  );
}