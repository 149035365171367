import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import OurMissionInfo from "./OurMissionInfo";

export default function OurMission() {
  return (
    <Box bgcolor="#DEF2F5">
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box
              height={468}
              sx={{
                backgroundImage: "url(/images/team.png)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Box
              pt={5}
              pb="60px"
            >
              <OurMissionInfo />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
