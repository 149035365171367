import React from "react";
import { Helmet } from "react-helmet-async";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function BillingAndReceiving() {
  return (
    <>
      <Helmet>
        <title>Billing & Receiving</title>
        <meta name="description" content="Warehouse Management" />
      </Helmet>
      <Header />
      <Box bgcolor="#FAFBFB" pb={15}>
        <Container>
          <Typography variant="h1" sx={{ pt: "82px" }}>
          Billing & Receiving
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <Typography variant="body1" sx={{ mt: 3, whiteSpace: "pre-line" }}>
                {'Streamline the receiving process for customer-owned inventory with our easy-to-use Receiving Assistant.  Onboard new team members in minutes and capture essential data right from the loading dock using a mobile device.  Keep your customers informed with instant email notifications on received shipments, and let our automated billing engine generate monthly storage reports effortlessly.'}
                <Typography mt={5}>
                  <CheckCircleIcon
                    sx={{ fontSize: 16, color: "#00698C", mb: "-2px" }}
                  />{" "}
                  Automated PDF receiving reports with pictures
                </Typography>
                <Typography mt={0.5}>
                  <CheckCircleIcon
                    sx={{ fontSize: 16, color: "#00698C", mb: "-2px" }}
                  />{" "}
                  Fully automated storage billing reports
                </Typography>
                <Typography mt={0.5}>
                  <CheckCircleIcon
                    sx={{ fontSize: 16, color: "#00698C", mb: "-2px" }}
                  />{" "}
                  Accessible on any device with a web browser
                </Typography>
                <Typography mt={0.5}>
                  <CheckCircleIcon
                    sx={{ fontSize: 16, color: "#00698C", mb: "-2px" }}
                  />{" "}
                  Configurable receiving process to adapt to customer needs
                </Typography>
                <Typography mt={0.5}>
                  <CheckCircleIcon
                    sx={{ fontSize: 16, color: "#00698C", mb: "-2px" }}
                  />{" "}
                  Track capture completion times for actionable insights
                </Typography>
              </Typography>
            </Grid>
            <Hidden>
              <Grid item md={7}>
                <Box p={2}>
                  <Box
                    position="relative"
                    component="img"
                    maxWidth="calc(100% - 6px)"
                    src="/images/solutions-billing-receiving.png"
                    alt=""
                  />
                </Box>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </>
  );
}